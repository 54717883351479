import { Component } from 'react';
import { Row } from 'react-bootstrap';
import _ from 'lodash';
import TopicListItem from "./TopicListItem";
import ListPagination from './ListPagination';


export default class TopicList extends Component {

  renderListFromData = () => {
    const topics = this.props.topics;
    const {
      domain = [],
      areaOfResponsibility = [],
      department = []
    } = _.isEmpty(this.props.tags) ? {} : this.props.tags;

    return topics.filter((topic) => topic.details.toLowerCase().includes(this.props.searchTerm.toLowerCase())
        || topic.title.toLowerCase().includes(this.props.searchTerm.toLowerCase())
        || this.props.searchTerm.length === 0)
        .filter(topic =>
          topic.dissertationLevel.find(dis =>
            this.props.dissertation.find(selectedDis => selectedDis.label === dis.label) !== undefined)
          || this.props.dissertation.length === 0)
        .filter(topic =>
          topic.expertise.find(exp =>
            this.props.expertise.find(selectedExp => selectedExp.label === exp.label )!== undefined)
          || this.props.expertise.length === 0)
        .filter(topic => topic.tags.find(tag => domain.find(dom => dom.name === tag.tag) !== undefined) || domain.length === 0)
        .filter(topic => topic.tags.find(tag => areaOfResponsibility.find(aor => aor.name === tag.tag) !== undefined) || areaOfResponsibility.length === 0)
        .filter(topic => topic.tags.find(tag => department.find(dep => dep.name === tag.tag) !== undefined) || department.length === 0);
  };

  render() {
    const result = this.renderListFromData();
    return (
      <Row>
        <div className="topic-list-container">
          {result !== null && result !== undefined && result.length > 0 && result.filter((topic, index) => (
                index < (this.props.active * this.props.perPage) &&
                index >= (this.props.active * this.props.perPage - this.props.perPage)
              )).map((data, index) => (
                <TopicListItem editing={this.props.editing} key={index} data={data} />
              ))
          }
        </div>
        <ListPagination
          topics={result}
          active={this.props.active}
          onChange={this.props.handlePagination}
          next={this.props.handlePaginationNext}
          prev={this.props.handlePaginationPrev}
          first={this.props.handlePaginationFirst}
          last={this.props.handlePaginationLast}
        />
      </Row>
    );
  }
}
