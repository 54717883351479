import { ROOT_URL } from '../constants'
import {fetchBuilder} from "./fetchBuilder";

export const SUBMIT_EDITED_TOPIC= "submit_edited_topic";
export const URL = `${ROOT_URL  }/topics`;


export function submitEditedTopic(topic, id) {

  const request = fetchBuilder(`${URL}/${id}/edit`, "POST", topic)
    .then(res => res.ok ? {} : new Error()).catch(err => console.log(err));

  return {
    type: SUBMIT_EDITED_TOPIC,
    payload: request
  }
}
