export const TopicSchema = {
  "type": "object",
  "required": [
    "focus",
    "title",
    "domain",
    "areaOfResponsibility",
    "dissertationLevel",
    "expertise",
    "details",
    "officialInstructor",
    "officialContact",
    "contact"
  ],
  "properties": {
    "focus": {
      "type": "string",
      "title": "Tutkimuksen ja kehittämisen painopistealue"
    },
    "title": {
      "type": "string",
      "title": "Aihe"
    },
    "domain": {
      "title": "Toimialue",
      "type": "array",
      "description": "Lisää toimialue painamalla enter -näppäintä",
      "items": {
        "type": "object",
        "properties": {
          "id": {
            "type": "number"
          },
          "name": {
            "type": "string",
            "minLength":1
          }
        }
      }
    },
    "areaOfResponsibility": {
      "type": "array",
      "title": "Vastuualue",
      "description": "Lisää vastuualue painamalla enter -näppäintä",
      "items": {
        "type": "object",
        "properties": {
          "id": {
            "type": "number"
          },
          "name": {
            "type": "string",
            "minLength":1
          }
        }
      }
    },
    "department": {
      "type": "array",
      "title": "Yksikkö",
      "description": "Lisää yksikkö painamalla enter -näppäintä",
      "items": {
        "type": "object",
        "properties": {
          "id": {
            "type": "number"
          },
          "name": {
            "type": "string",
            "minLength":1
          }
        }
      }
    },
    "dissertationLevel": {
      "type": "array",
      "title": "Opinnäytetyön taso",
      "items": {
        "type":"object",
        "properties": {
          "value": {
            "type": "string"
          },
          "label": {
            "type": "string"
          }
        }
      }
    },
    "expertise": {
      "type": "array",
      "title":"Aihealue",
      "items": {
        "type":"object",
        "properties": {
          "value": {
            "type": "string"
          },
          "label": {
            "type": "string"
          }
        }
      }
    },
    "details": {
      "type": "string",
      "title": "Työelämän tarve opinnäytetyölle"
    },
    "researchMethod": {
      "type": "string",
      "title": "Työelämän ehdotus aineiston keruun menetelmästä",
    },
    "officialInstructor": {
      "type": "string",
      "title": "Työelämäohjaaja",
    },
    "officialContact": {
      "type": "string",
      "title": "Työelämän yhteyshenkilö",
    },
    "contact": {
      "type": "array",
      "title": "Työelämän yhteyshenkilön sähköposti",
      "description": "Lisää yhteyshenkilön sähköposti painamalla enter -näppäintä",
      "items": {
        "type": "object",
        "properties": {
          "id": {
            "type": "number"
          },
          "name": {
            "type": "string",
            "minLength":1
          }
        }
      }
    },
  }
};

export const TopicUISchema = {
  "focus": {
    "ui:autofocus":true,
    "ui:emptyValue": ""
  },
  "domain": {
    "ui:field": "domain",
  },
  "areaOfResponsibility": {
    "ui:field": "areaOfResponsibility",
  },
  "department": {
    "ui:field": "department",
  },
  "contact": {
    "ui:field": "contact"
  },
  "dissertationLevel": {
    "ui:field": "dissertationLevel"
  },
  "expertise": {
    "ui:field": "expertise"
  },
  "details": {
    "ui:widget": "textarea",
    "ui:options": {
      "rows":5
    }
  },
  "researchMethod": {
    "ui:widget":"textarea",
    "ui:options": {
      "rows":5
    }
  }
};
