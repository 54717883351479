import { Component } from 'react';
import { FormGroup, Button } from 'react-bootstrap';
import Form from 'react-jsonschema-form';
import {StudentSchema, StudentUISchema} from "./schema/StudentSchema";
import { Empty } from './Empty';

export default class AdditionalStudentPanel extends Component {

  callParent = () => {
    this.props.onClick(this.props.id);
  };

  render() {
    return (
      <FormGroup style={{paddingTop:'5px'}}>
        <div style={{overflow:'hidden'}}>
          <h3 style={{float:'left'}}>Opiskelija</h3>
          <Button
            className="btn-oppa-danger btn"
            onClick={this.callParent}
            style={{float:'right', position:'relative', marginTop:'17px'}}>Poista opiskelija</Button>
        </div>
        <hr />
        <Form
          ref={this.props.reference}
          schema={StudentSchema}
          uiSchema={StudentUISchema}
          onChange={this.props.onChange}
          formData={this.props.students}
          idPrefix={`${this.props.id}`}
          FieldTemplate={this.props.FieldTemplate}
          liveValidate={true}
          showErrorList={false}>
            <Empty />
        </Form>
      </FormGroup>
    );
  }
}
