import {CLEAR_TOPICS_FINISHED, FETCH_TOPICS} from "../components/actions/fetchTopics";


export default function(state = {data:[], finished:false}, action) {
  switch(action.type) {
    case FETCH_TOPICS:
      return {
        ...state,
        data: action.payload,
        finished: true
      };
    case CLEAR_TOPICS_FINISHED:
      return {
        ...state,
        finished: false
      };
    default:
      return state;
  }
}
