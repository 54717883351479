import { Component } from 'react';
import {DATA_PRIVACY_URL} from "./constants";
import { Link, withRouter } from 'react-router-dom';

export default class Footer extends Component {


  render() {
    return(
      <div className="oppa_footer">
        <Link to={
          {
            pathname: `/tietosuojaseloste`,
            state: {
              fromFooter: true
            }
          }
        }>
          Tietosuojaseloste
        </Link>
      </div>
    );
  }
}
