import { ROOT_URL } from '../constants'
import {fetchBuilder} from "./fetchBuilder";

export const FETCH_SUGGESTIONS = "fetch_suggestions";

export function fetchSuggestions() {

  const request = fetchBuilder(`${ROOT_URL}/suggestions`, "GET")
    .then(res => res.json()).catch(err => console.log(err));

  return {
    type: FETCH_SUGGESTIONS,
    payload: request
  }
}
