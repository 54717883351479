import { ROOT_URL } from '../constants';
import {fetchBuilder} from "./fetchBuilder";

export const REMOVE_EXISTING_TOPIC = 'remove_existing_topic';

export function removeExistingTopic(id) {

  const request = fetchBuilder(`${ROOT_URL}/topics/${id}/delete`, "POST")
    .then(res => res.json()).catch(err => err);

  return {
    type: REMOVE_EXISTING_TOPIC,
    payload: request
  };
}
