import { Modal, Button } from 'react-bootstrap';
import { Component } from 'react';

export default class ConfirmActionModal extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.toggleModal}>
        <Modal.Header>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="topic-list-modal-body">
          <p>{this.props.message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.toggleModal}>Peruuta</Button>
          <Button onClick={this.props.modalAction} className="btn-oppa-danger btn">{this.props.action}</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
