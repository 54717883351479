import { Component } from 'react';
import ReactTags from 'react-tag-autocomplete';
import {fetchTopicAndReservation} from "./actions/fetchTopicAndReservation";
import {removeExistingTopic} from "./actions/removeExistingTopic";
import {changeTopicAndReservation} from "./actions/changeTopicAndReservation";
import {connect} from "react-redux";
import {submitEditedTopicAndReservation} from "./actions/submitEditedTopicAndReservation";
import {submitEditedTopic} from "./actions/submitEditedTopic";

class FilterTagInput extends Component {


  state = {
    tags: this.props.formData,
    suggestions:[]
  };

  errorClassNames = {
    root: 'error-react-tags',
    rootFocused: 'error-is-focused',
    selected: 'error-react-tags__selected',
    selectedTag: 'error-react-tags__selected-tag',
    selectedTagName: 'error-react-tags__selected-tag-name',
    search: 'error-react-tags__search',
    searchInput: 'error-react-tags__search-input',
    suggestions: 'error-react-tags__suggestions',
    suggestionActive: 'error-is-active',
    suggestionDisabled: 'error-is-disabled'
  };

  successClassNames = {
    root: 'react-tags',
    rootFocused: 'is-focused',
    selected: 'react-tags__selected',
    selectedTag: 'react-tags__selected-tag',
    selectedTagName: 'react-tags__selected-tag-name',
    search: 'react-tags__search',
    searchInput: 'react-tags__search-input',
    suggestions: 'react-tags__suggestions',
    suggestionActive: 'is-active',
    suggestionDisabled: 'is-disabled'
  };

  componentWillMount() {
    switch(this.props.name) {
      case "domain":
        this.setState({
          suggestions: [...this.props.suggestions.domain]
        });
        return;
      case "areaOfResponsibility":
        this.setState({
          suggestions: [...this.props.suggestions.areaOfResponsibility]
        });
        return;
      case "department":
        this.setState({
          suggestions: [...this.props.suggestions.department]
        });
        return;
      case "contact":
        this.setState({
          suggestions: [...this.props.suggestions.contact]
        });
        return;
    }
  }

  handleAddition = (tag) => {
    let tags;
    if(this.state.tags !== undefined) {
      tags = [...this.state.tags, tag];
    } else {
      tags = [tag];
    }
    this.setState({tags}, () => {
      this.props.onChange(this.state.tags);
    });
  };

  handleDelete = (i) => {
    let tags = [...this.state.tags];
    tags.splice(i, 1);

    this.setState({tags}, () => {
      this.props.onChange(this.state.tags);
    });
  };

  render() {
    return (
      <div>
        <ReactTags
          tags={this.state.tags}
          suggestions={this.state.suggestions}
          handleDelete={this.handleDelete}
          handleAddition={this.handleAddition}
          delimiters={[9,13]}
          placeholder={"Aloita kirjoittamalla.."}
          allowNew={true}
          allowBackspace={false}
          autofocus={false}
          classNames={this.state.tags !== undefined && this.state.tags.length > 0 || this.props.name === "department" ? this.successClassNames : this.errorClassNames }
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    suggestions:state.suggestions
  }
}

export default connect(mapStateToProps, null)(FilterTagInput);
