import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

class TopicListItem extends Component {

  render() {
    return (
      <div className="topic-list-item">
        <h2>{this.props.data.title}</h2>
        <div style={{display:'inline-block'}}>
          <div className="topic-list-item-sub-info">
            <h3>Toimeksiantaja:</h3>
            {this.props.data.tags.map((tag, i) => tag.type === "domain" ? <p key={i}>{tag.tag},</p> : null)}
            {this.props.data.tags.map((tag, i) => tag.type === "areaOfResponsibility" ? <p key={i}>{tag.tag},</p> : null)}
            {this.props.data.tags.map((tag, i) => tag.type === "department" ? <p key={i}>{tag.tag}</p> : null)}
          </div>
          <div className="topic-list-item-sub-info">
            <h3>Opinnäytetyön taso:</h3>
            { this.props.data.dissertationLevel.map((dis, i) => <p key={i}>{dis.label}</p>) }
          </div>
        </div>
        <div className="topic-list-item-introduction">
          <p>{ this.props.data.details.substring(0, 250) }...</p>
        </div>
        <div className="topic-list-item-btn-row">
          <Link to={{ pathname: this.props.editing !== 1 ? `/topics/${this.props.data.id}` : `/topics/${this.props.data.id}/edit`}}>
            <Button className="btn-oppa-info btn">Lisätiedot</Button>
          </Link>
        </div>
      </div>
    );
  }
}
export default TopicListItem;
