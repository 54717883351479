import { ROOT_URL } from '../constants'
import {fetchBuilder} from "./fetchBuilder";

const URL = `${ROOT_URL  }/topics`;

export const POST_TOPIC = "post_topic";

export function postTopic(body) {

  const request = fetchBuilder(URL, "POST", body)
    .then(res => ({status:"success"})).catch(err => ({status:"failed"}));

  return {
    type: POST_TOPIC,
    payload: request
  }
}
