import { ROOT_URL } from '../constants'
import {fetchBuilder} from "./fetchBuilder";

const URL = `${ROOT_URL  }/topics`;
export const POST_RESERVATION = "post_reservation";


export function postReservation(id, reservation, students) {

  const request = fetchBuilder(`${URL}/${Number(id)}/reservations`, "POST", reservation)
    .then(res => res.json())
    .then(res => fetchBuilder(`${URL}/${Number(id)}/reservations/${res}`, "POST", students))
    .then(res => res.json()).catch(err => ({"error": "400"}));

  return {
    type: POST_RESERVATION,
    payload: request
  }
}
