import { Component } from 'react';
import { hot } from 'react-hot-loader';
import { Localize } from '@visma/react-intl-helpers';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import promise from 'redux-promise';
import reducers from '../../reducers/index.js';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Grid, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { portletBTitle } from '../../../resources/intl/messages.properties';
import Title from '../Title';
import TopicList from "../TopicList";
import Topic from "../Topic";
import ReservationForm from '../reservation/ReservationForm';
import ReservationResult from '../reservation/ReservationResult';
import ContainerB from "../ContainerB";
import TopicForm from "../TopicForm";
import TopicEditForm from "../TopicEditForm";
import DataPrivacy from "../DataPrivacy";

const createStoreWithMiddleware = applyMiddleware(promise)(createStore);

class PortletB extends Component {
  render() {
    return (
      <div className="oppa-wrapper">
        <Provider store={createStoreWithMiddleware(reducers)}>
          <HashRouter>
            <Switch>
              <Route path="/topics/:id/reservation/summary" render={props => <ReservationResult id={props.match.params.id} />} />
              <Route path="/topics/:id/reservation" render={props => <ReservationForm id={props.match.params.id} />} />
              <Route path="/topics/:id/edit" render={props => <TopicEditForm id={props.match.params.id} />} />
              <Route path="/topics/new" render={props => <TopicForm />} />
              <Route path="/topics/:id" render={props => <Topic id={props.match.params.id} />} />
              <Route path="/tietosuojaseloste" render={props => <DataPrivacy nav={props} />} />
              <Route path="/" component={ContainerB} />
            </Switch>
          </HashRouter>
        </Provider>
      </div>
    );
  }
}

export default hot(module)(PortletB);
