/* eslint import/no-webpack-loader-syntax: "off" */
/* eslint import/no-unresolved: "off" */
import '!style-loader!css-loader!sass-loader!../styles/index.scss';
import { render } from 'react-dom';
import PortletB from '../components/PortletB';
import Footer from '../components/Footer';
import { HashRouter } from 'react-router-dom'

// render(<App />, document.body.appendChild(document.createElement('div')));
render(<PortletB />, document.getElementById("oppaPortletB"));

window.onload = function() {
  render(<HashRouter><Footer /></HashRouter>, document.getElementById("footer"));
};
