import { ROOT_URL } from '../constants'
import {fetchBuilder} from "./fetchBuilder";

const URL = `${ROOT_URL  }/topics/`;
export const FETCH_TOPICS = "fetch_topics";
export const CLEAR_TOPICS_FINISHED = "clear_topics_finished";

export function fetchTopics(status) {

  const request = fetchBuilder(`${URL}${status ? "reserved" : "available"}`, "GET")
    .then(res => res.json()).catch(err => ({ "error":"400" }));

  return {
    type: FETCH_TOPICS,
    payload: request
  }
}

export function clearTopicsFinished() {
  return {
    type: CLEAR_TOPICS_FINISHED
  }
}
