import {FETCH_TOPIC} from "../components/actions/fetchSingleTopic";
import {CLEAR_TOPIC} from "../components/actions/clearTopic";

export default function(state = {}, action) {
  switch(action.type) {
    case FETCH_TOPIC:
      return action.payload;
    case CLEAR_TOPIC:
      return action.payload;
    default:
      return state;
  }
}
