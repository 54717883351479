import { Component, createRef } from 'react';
import { FormGroup, ControlLabel, FormControl, Grid, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { postTopic } from './actions/postTopic';
import { clearTopic } from './actions/clearTopic';
import _ from 'lodash';
import Header from './Header';
import Form from 'react-jsonschema-form';
import FilterTagInput from './FilterTagInput';
import {TopicSchema, TopicUISchema} from "./schema/TopicSchema";
import { Empty } from './Empty';
import Loader from './Loader';
import MultiSelectField from "./MultiSelectField";

const fields = {
  domain: FilterTagInput,
  areaOfResponsibility: FilterTagInput,
  department: FilterTagInput,
  contact: FilterTagInput,
  dissertationLevel: MultiSelectField,
  expertise: MultiSelectField
};

class TopicForm extends Component {

  state = {
    topic: {},
    prevTopicPosted: this.props.topicPosted,
    loading:false,
    valid:false
  };

  CustomFieldTemplate = (props) => {
    const {id, classNames, label, help, required, description, children} = props;
    return (
      <div className={"topic-form-tag-field " + classNames}>
        <label className="control-label" htmlFor={id}>{label}{required ? "*" : null}</label>
        {children}
        {description}
      </div>
    );
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "Uusi aihe - Opinnäytetyöaiheet"
  }

  static getDerivedStateFromProps(props, state) {
    if(props.topicPosted !== state.prevTopicPosted) {
      return {
        loading:false,
        prevTopicPosted: props.topicPosted
      };
    }
    return null;
  }

  checkValidationState = () => {
    let temp = this.state.valid;
    const topic = this.state.topic;
    temp = this.topicRef.state.errors.length === 0;
    this.setState({valid:temp});
  };

  validateTagFields = (formData, errors) => {
    if(formData.domain !== undefined && formData.domain.length === 0) {
      errors.domain.addError("Field requires atleast one (1) entry.");
    }
    if(formData.areaOfResponsibility !== undefined && formData.areaOfResponsibility.length === 0) {
      errors.areaOfResponsibility.addError("Field requires atleast one (1) entry.");
    }
    if(formData.dissertationLevel !== undefined && formData.dissertationLevel.length === 0) {
      errors.dissertationLevel.addError("Field requires atleast one (1) entry.");
    }
    if(formData.expertise !== undefined && formData.expertise.length === 0) {
      errors.expertise.addError("Field requires atleast one (1) entry.");
    }
    if(formData.contact !== undefined && formData.contact.length === 0) {
      errors.contact.addError("Field requires atleast one (1) entry.");
    }
    return errors;
  };

  handleChange = (form) => {
    this.setState({topic:form.formData}, () => {this.checkValidationState();});
  };

  handleFormSubmit = (form) => {
    this.setState({loading:true}, () => {
      this.props.postTopic(this.state.topic);
    });
  };

  componentWillUnmount() {
    this.props.clearTopic();
  }

  render() {

    if(this.state.loading) {
      return (
        <div>
          <Header />
          <Loader/>
        </div>
      );
    }

    if(!_.isEmpty(this.props.topicPosted)) {
      return (
        <div>
          <Header />
          <Grid className="oppa-content-container">
            <Row>
              <div className="topic-info-container">
                <div className="topic-info-container-inner">
                  <div className="topic-info-modal">
                    <div className="topic-info-modal-header">
                      <h4>Kiitos aiheen lisäyksestä</h4>
                    </div>
                    <div className="topic-info-modal-content">
                      <p>Aihe on lisätty järjestelmään onnistuneesti!</p>
                    </div>
                    <div className="topic-info-modal-buttons">
                      <Link to={{pathname: '/'}}>
                        <Button bsStyle="primary">Takaisin etusivulle</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Grid>
        </div>
      );
    }
    return (
      <div>
        <Header />
        <Grid className="oppa-content-container">
          <Row>
            <div className="topic-info-container">
              <div className="topic-info-container-inner">
                <div className="topic-info-modal">
                  <div className="topic-info-modal-header">
                    <h1>Aiheen tiedot</h1>
                  </div>
                  <div className="topic-info-modal-content">
                    <Form
                      ref={(form) => {this.topicRef=form;}}
                      schema={TopicSchema}
                      uiSchema={TopicUISchema}
                      fields={fields}
                      liveValidate={true}
                      formData={this.state.topic}
                      FieldTemplate={this.CustomFieldTemplate}
                      onChange={this.handleChange}
                      showErrorList={false}
                      validate={this.validateTagFields}
                      idPrefix={"rjsf_topic"}>
                      <Empty/>
                    </Form>
                  </div>
                  <div className="topic-info-modal-buttons">
                    <Button disabled={!this.state.valid} onClick={this.handleFormSubmit} className="btn-oppa-primary btn">Tallenna</Button>
                    <Link to={{pathname: `/`}}>
                      <Button>Takaisin</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    topicPosted: state.topicPosted,
    topic: state.topic,
  };
}

export default connect(mapStateToProps, { postTopic, clearTopic })(TopicForm);
