import { Component, createRef } from 'react';
import { FormGroup, Panel, ControlLabel, FormControl, HelpBlock, Grid, Row, Button} from 'react-bootstrap';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { saveReservation } from "../actions/saveReservation";
import { clearPostedReservation} from "../actions/clearPostedReservation";
import { Redirect } from 'react-router-dom';
import AdditionalStudentPanel from "../AdditionalStudentPanel";
import Header from "../Header";
import Form from 'react-jsonschema-form';
import { Empty } from '../Empty';
import {StudentSchema, StudentUISchema} from "../schema/StudentSchema";
import {TeacherSchema, TeacherUISchema} from "../schema/TeacherSchema";

class ReservationForm extends Component {

  constructor(props) {
    super(props);
    this.s0F = createRef();
    this.tF = createRef();
    this.s1F = createRef();
    this.s2F = createRef();
  }

  state = {
    students: [
      {},
      {},
      {}
    ],
    teacher: {},
    preventAdditionalStudents: false,
    additionalStudents: [],
    valid: false,
    submitted: false
  };

  CustomFieldTemplate = (props) => {
    const {id, classNames, label, help, required, description, children} = props;
    return (
      <div className={classNames}>
        <label className="control-label" htmlFor={id}>{label}{required ? "*" : null}</label>
        {description}
        {children}
        {help}
      </div>
    );
  };

  componentWillMount() {
    document.title = "Varauslomake - Opinnäytetyöaiheet";
    this.setState({submitted: false}
    , () => {
      if(!_.isEmpty(this.props.reservation)) {
        this.setState({
          students: this.props.reservation.students,
          teacher: this.props.reservation.reservation.teacher,
          valid: true
        });
      }
    }
    , () => {
        this.props.clearPostedReservation();
      }
    );
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    if(!_.isEmpty(this.props.reservation)) {
      if (this.props.reservation.students.length > 1) {
        const temp = [];
        for (let i = 0 ; i < this.props.reservation.students.length; i++) {
          if(i !== 0) {
            if(i === 1) {
              temp.push(
                <AdditionalStudentPanel
                  reference={(form) => {this.s1F=form;}}
                  key={1}
                  id="1"
                  onChange={this.handleStudentChange}
                  students={this.props.reservation.students[1]}
                  onClick={this.removeAdditionalStudent}
                  FieldTemplate={this.CustomFieldTemplate}
                />
              );
            } else {
              temp.push(
                <AdditionalStudentPanel
                  reference={(form) => {this.s2F=form;}}
                  key={2}
                  id="2"
                  onChange={this.handleStudentChange}
                  students={this.props.reservation.students[2]}
                  onClick={this.removeAdditionalStudent}
                  FieldTemplate={this.CustomFieldTemplate}
                />
              );
            }
          }
        }
        this.setState({additionalStudents: temp}, () => {
          if(temp.length > 1) {
            this.setState({preventAdditionalStudents:true}, () => {
              this.checkValidationState();
            });
          }
        });
      }
    }
  }

  checkValidationState = () => {
    let temp = this.state.valid;
    if(this.s0F.state.errors.length === 0 && this.tF.state.errors.length === 0) {
      if(this.state.additionalStudents.length > 0) {
        if(this.state.additionalStudents.length > 1) {
          temp = this.s1F.state.errors.length === 0 && this.s2F.state.errors.length === 0;
        } else {
          temp = this.s1F !== null && this.s1F.state !== undefined && this.s1F.state.errors.length === 0
            || this.s2F !== null && this.s2F.state !== undefined && this.s2F.state.errors.length === 0;
        }
      } else {
        temp = true;
      }
    } else {
      temp = false;
    }
    this.setState({valid:temp});
  };

  handleStudentChange = (form) => {
    const temp = this.state.students;
    temp[Number(form.idSchema.$id)] = form.formData;
    this.setState({students: temp}, () => this.checkValidationState());
  };

  handleTeacherChange = (form) => {
    let temp = this.state.teacher;
    temp = form.formData;
    this.setState({teacher: temp}, () => this.checkValidationState());
  };

  handleFormSubmit = () => {
    if(this.state.valid) {
      const temp = [...this.state.students];

      if(this.state.additionalStudents.length === 0) {
        temp.splice(1);
      } else if(this.state.additionalStudents.length === 1) {
        temp.splice(2, 1);
      }

      this.setState({students:temp, submitted:true}, () => {
        this.props.saveReservation(this.props.id, {
          "topicId":this.props.id,
          "teacher": this.state.teacher
        }, this.state.students);
      });
    }
  };

  removeAdditionalStudent = (id) => {
    const temp = [...this.state.additionalStudents];
    const tempStudents = this.state.students;
    const index = temp.findIndex((student) => student.props.id === id);
    temp.splice(index, 1);
    this.setState({additionalStudents: temp}, () => {
      tempStudents[Number(id)] = {};
      this.setState({students: tempStudents})
    }, () => {
      this.checkValidationState();
    });
    if(temp.length < 2) {
      this.setState((prevState) => {
        if(prevState.preventAdditionalStudents) {
          return { preventAdditionalStudents: !prevState.preventAdditionalStudents }
        }
      }, () => this.checkValidationState());
    }
  };

  addAdditionalStudent = () => {
    if(!this.state.preventAdditionalStudents) {
      const temp = [...this.state.additionalStudents];
      if(temp.length > 0 && Number(temp[0].props.id) === 1) {
        temp.push(
          <AdditionalStudentPanel
            reference={(form) => {this.s2F=form;}}
            key={2}
            id="2"
            onChange={this.handleStudentChange}
            students={this.state.students[2]}
            onClick={this.removeAdditionalStudent}
            FieldTemplate={this.CustomFieldTemplate}
          />
        );
      } else if(temp.length > 0 && Number(temp[0].props.id) === 2) {
        temp.push(
          <AdditionalStudentPanel
            reference={(form) => {this.s1F=form;}}
            key={1}
            id="1"
            onChange={this.handleStudentChange}
            students={this.state.students[1]}
            onClick={this.removeAdditionalStudent}
            FieldTemplate={this.CustomFieldTemplate}
          />
        );
      } else if(temp.length === 0) {
        temp.push(
          <AdditionalStudentPanel
            reference={(form) => {this.s1F=form;}}
            key={temp.length + 1}
            id={`${temp.length + 1}`}
            onChange={this.handleStudentChange}
            students={this.state.students[temp.length + 1]}
            onClick={this.removeAdditionalStudent}
            FieldTemplate={this.CustomFieldTemplate}
          />
        );
      }

      this.setState({additionalStudents: temp}, () => {
        if(temp.length === 2) {
          this.setState((prevState) => ({ preventAdditionalStudents: !prevState.preventAdditionalStudents }), () => this.checkValidationState());
        } else {
          this.checkValidationState();
        }
      });
    }
  };

  render() {
    if(!_.isEmpty(this.props.reservation) && this.state.submitted) {
      return (
        <Redirect
          push
          to={{
          pathname: `/topics/${this.props.id}/reservation/summary`
        }}
        />
      );
    }
    return (
      <div>
        <Header />
        <Grid className="oppa-content-container">
          <Row>
            <div className="topic-info-container">
              <div className="topic-info-container-inner">
                <div className="topic-info-modal">
                  <div className="topic-info-modal-header">
                    <h1>Varauksen tiedot</h1>
                  </div>
                  <div className="topic-info-modal-content">
                    <h2 className="topic-reservation-subtitle">Opinnäytetyön tekijät</h2>
                    <FormGroup>
                      <h3>Opiskelija</h3>
                      <hr />
                      <Form
                        idPrefix="0"
                        ref={(form) => {this.s0F=form;}}
                        schema={StudentSchema}
                        uiSchema={StudentUISchema}
                        onChange={this.handleStudentChange}
                        formData={this.state.students[0]}
                        FieldTemplate={this.CustomFieldTemplate}
                        liveValidate
                        showErrorList={false}
                      >
                        <Empty />
                      </Form>
                    </FormGroup>
                    {this.state.additionalStudents}
                    <FormGroup>
                      <Button
                        className="btn-oppa-success btn"
                        disabled={this.state.preventAdditionalStudents}
                        onClick={this.addAdditionalStudent}
                      >
                        + Lisää uusi opiskelija
                      </Button>
                    </FormGroup>
                    <hr />
                    <FormGroup style={{marginTop:"50px"}}>
                      <h2>Ohjaava opettaja / oppilaitoksen yhteyshenkilö</h2>
                      <Form
                        idPrefix="1"
                        ref={(form) => {this.tF=form;}}
                        schema={TeacherSchema}
                        uiSchema={TeacherUISchema}
                        onChange={this.handleTeacherChange}
                        liveValidate
                        formData={this.state.teacher}
                        FieldTemplate={this.CustomFieldTemplate}
                        showErrorList={false}
                      >
                        <Empty />
                      </Form>
                    </FormGroup>
                  </div>
                  <div className="topic-info-modal-buttons">
                    <Button
                      disabled={!this.state.valid}
                      onClick={this.handleFormSubmit}
                      className="btn-oppa-primary btn"
                    >Seuraava
                    </Button>
                    <Link to={{pathname: `/topics/${this.props.id}`}}>
                      <Button>Takaisin</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {reservation:state.reservation}
}

export default connect(mapStateToProps, { saveReservation, clearPostedReservation })(ReservationForm);
