import {CLEAR_RESERVATION} from "../components/actions/clearReservation";
import {SAVE_RESERVATION} from "../components/actions/saveReservation";

export default function(state = {}, action) {
  switch(action.type) {
    case SAVE_RESERVATION:
      return action.payload;
    case CLEAR_RESERVATION:
      return action.payload;
    default:
      return state;
  }
}
