import { Component } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { dissertationOptions, expertiseOptions } from './constants';

export default class MultiSelectFilter extends Component {

  state = {
    selectedOption: this.props.data
  };

  colourStylesValid = {
    control: (styles, { isFocused, hasValue }) => ({
      ...styles,
      borderColor: isFocused ? '#66afe9' : 'rgb(209, 209, 209)',
      ':hover': {
        borderColor: '#66afe9',
      },
      boxShadow: isFocused
        ? `inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #66afe9`
        : null
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor:'white',
      ':hover': {
        backgroundColor: '#203a7f',
        color: 'white'
      }
    }),
    placeholder: (provided, state) => {
      const color = '#757575';
      return { ...provided, color };
    }
  };


  handleChange = (selectedOption) => {
    this.setState({selectedOption}, () => {
      this.props.onChange(this.state.selectedOption, this.props.name);
    });
  };

  render() {
    const { selectedOption } = this.state;
    const { name } = this.props;
    return (
      <div>
        <Select
          options={name === 'dissertationLevel' ? dissertationOptions : expertiseOptions}
          value={selectedOption}
          onChange={this.handleChange}
          isMulti
          styles={this.colourStylesValid}
          placeholder={
            name === 'dissertationLevel'
              ? 'Opinnäytetyön taso'
              : 'Aihealueet'
          }
          theme={(theme) => ({
            ...theme,
              colors: {
                ...theme.colors,
                neutral50: '#757575'
              }
          })}
        />
      </div>
    );
  }
}
