import { uniq } from 'lodash';

export default (locale, attempts) => {
  const [language, country] = locale.split('-');
  const localeParts = { country, language, locale };
  return uniq(
    attempts
      .map(attempt => (attempt === null ? null : localeParts[attempt]))
      .filter(attempt => attempt !== undefined)
  );
};
