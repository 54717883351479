import { Component } from 'react';
import { Grid, Col, Row, Button, Tab, Nav, NavItem} from 'react-bootstrap';
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import FilterBarExtended from "./FilterBarExtended";
import {fetchTopics, clearTopicsFinished} from "./actions/fetchTopics";
import {fetchSuggestions} from "./actions/fetchSuggestions";
import TopicList from "./TopicList";
import ListPagination from "./ListPagination";
import Header from './Header';
import Loader from "./Loader";
import TopicListTabTitle from "./TopicListTabTitle";



class ContainerB extends Component {

  state = {
    active: 1,
    perPage: 5,
    activeTab:1,
    status: false,
    searchTerm: '',
    expertise: [],
    dissertationLevel: [],
    results: 0,
    tags:{
      domain:[],
      areaOfResponsibility:[],
      department:[]
    }
  };

  handleTab = (e) => {
    if(this.props.topics.finished) {
      this.props.clearTopicsFinished();
    }
    if(e === 1 && this.state.status === true || e === 2 && this.state.status === false) {
      this.setState((prevState) => ({ status: !prevState.status }), () => this.props.fetchTopics(this.state.status));
    } else {
      this.props.fetchTopics(this.state.status);
    }
  };

  handlePagination = (e) => {
    if(e.target.tagName.toLowerCase() !== 'span') {
      this.setState({active: Number(e.target.text)});
    }
    window.scrollTo(0, 0);
  };

  handlePaginationNext = (e) => {
    if(this.state.active + 1 < this.props.topics.data.length / 5
      || this.state.active + 1 <= this.props.topics.data.length / 5 && this.props.topics.data.length % 5 !== 0) {
      this.setState(prevState => {
        return { active: prevState.active + 1 }
      });
    }
    window.scrollTo(0, 0);
  };

  handlePaginationPrev = (e) => {
    if(this.state.active - 1 !== 0) {
      this.setState(prevState => {
        return {
          active: prevState.active - 1
        }
      })
    }
    window.scrollTo(0, 0);
  };

  handlePaginationFirst = (e) => {
    this.setState({active: 1});
    window.scrollTo(0, 0);
  };

  handlePaginationLast = (e) => {
    if(this.props.topics.data.length % 5 !== 0) {
      this.setState({active: Math.ceil(this.props.topics.data.length / 5)});
    } else {
      this.setState({active: this.props.topics.data.length / 5});
    }
    window.scrollTo(0, 0);
  };

  handleSelect = (selected, name) => {
    this.setState({active: 1}, () => {
      name === 'dissertationLevel'
        ? this.setState({dissertationLevel: selected})
        : this.setState({expertise: selected});
    });

  };

  handleSearch = (e) => {
    this.setState({
      searchTerm: e.target.value,
      active: 1
    });
  };


  handleTagAddition = (tags) => {
    this.setState({tags});
  };

  componentDidMount() {
    this.props.fetchSuggestions();
    window.scrollTo(0, 0);
    document.title = "Etusivu - Opinnäytetyöaiheet";
    this.handleTab();
  }

  tabComponents() {
    if(this.props.topics.data.length === 0 && !this.props.topics.finished) {
      return(
        [
          <FilterBarExtended
            suggestions={this.props.suggestions !== undefined ? this.props.suggestions : {}}
            onChange={this.handleTagAddition}
            handleSearch={this.handleSearch}
            handleSelect={this.handleSelect}
            dissertation={this.state.dissertationLevel}
            expertise={this.state.expertise}
            key={0}
          />,
          <Loader />
        ]
      );
    }

    return (
      [
        <FilterBarExtended
          suggestions={this.props.suggestions !== undefined ? this.props.suggestions : {}}
          onChange={this.handleTagAddition}
          handleSearch={this.handleSearch}
          handleSelect={this.handleSelect}
          dissertation={this.state.dissertationLevel}
          expertise={this.state.expertise}
          key={0}
        />,
        <TopicList
          tags={this.state.tags}
          searchTerm={this.state.searchTerm}
          dissertation={this.state.dissertationLevel}
          expertise={this.state.expertise}
          topics={this.props.topics.data}
          active={this.state.active}
          perPage={this.state.perPage}
          handlePagination={this.handlePagination}
          handlePaginationNext={this.handlePaginationNext}
          handlePaginationPrev={this.handlePaginationPrev}
          handlePaginationFirst={this.handlePaginationFirst}
          handlePaginationLast={this.handlePaginationLast}
          editing={1}
          key={1}
        />
      ]
    );
  }


  render() {
    if(_.has(this.props.topics.data, "error")) {
      return (
        <div>
          <Header />
          <Grid>
            <Row>
              <div className="topic-info-container">
                <div className="topic-info-container-inner">
                  <div className="topic-info-modal">
                    <div className="topic-info-modal-header">
                      <h4>Yhteysvirhe</h4>
                    </div>
                    <div className="topic-info-modal-content">
                      <p>Palveluun ei juuri nyt saada yhteyttä. Yritä myöhemmin uudelleen.</p>
                    </div>
                    <div className="topic-info-modal-buttons" />
                  </div>
                </div>
              </div>
            </Row>
          </Grid>
        </div>
      );
    }


    return (
      <div>
        <Header />
        <Grid style={{marginBottom:'20px'}} />
        <Grid className="oppa-content-container-private">
          <Row>
            <Tab.Container defaultActiveKey={this.state.activeTab} id="topicTabs">
              <Row>
                <Col xs={4}>
                  <Nav bsStyle="tabs" style={{"float":"left", "minWidth":"270px"}}>
                    <NavItem onSelect={this.handleTab} eventKey={1}>Vapaat aiheet</NavItem>
                    <NavItem onSelect={this.handleTab} eventKey={2}>Varatut aiheet</NavItem>
                  </Nav>
                </Col>
                <Col xs={3} style={{"float":"right"}}>
                  <Link to={{pathname: '/topics/new'}}>
                    <Button className="btn-oppa-success btn tab-bar-btn">Lisää uusi aihe</Button>
                  </Link>
                </Col>
                <Col xs={12}>
                  <TopicListTabTitle tab={this.state.status ? 2 : 1} />
                  <Tab.Content animation={false}>
                    <Tab.Pane eventKey={1}>
                      {this.tabComponents()}
                    </Tab.Pane>
                    <Tab.Pane eventKey={2}>
                      {this.tabComponents()}
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Row>
        </Grid>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return { topics: state.topics, suggestions: state.suggestions };
}

export default connect(mapStateToProps, { clearTopicsFinished, fetchTopics, fetchSuggestions })(ContainerB);
