
export const ROOT_URL = OPPA.resourceURL  + "&" + OPPA.nameSpace + "param=/api"; //OPPA_CONFIG.ROOT_URL;
export const RESERVATION_GUIDE_URL = "https://www.tays.fi/fi-FI/Koulutus/Opetus/Opinnaytetyot";

export const dissertationOptions = [
  {value: 'AMK', label: 'AMK'},
  {value: 'YAMK', label: 'YAMK'},
  {value: 'Kehittämistyö', label: 'Kehittämistyö'},
  {value: 'Kandityö', label: 'Kandityö'},
  {value: 'Yliopiston syventävä projektityö', label: 'Yliopiston syventävä projektityö'},
  {value: 'Pro Gradu', label: 'Pro Gradu'},
  {value: 'Muu', label: 'Muu'}
];

export const expertiseOptions = [
  {value: 'Kirurginen hoitotyö', label: 'Kirurginen hoitotyö'},
  {value: 'Sisätautien hoitotyö', label: 'Sisätautien hoitotyö'},
  {value: 'Lasten- ja nuorten hoitotyö', label: 'Lasten- ja nuorten hoitotyö'},
  {value: 'Mielenterveys- ja päihdehoitotyö', label: 'Mielenterveys- ja päihdehoitotyö'},
  {value: 'Kehitysvammaisten hoitotyö', label: 'Kehitysvammaisten hoitotyö'},
  {value: 'Perioperatiivinen hoitotyö', label: 'Perioperatiivinen hoitotyö'},
  {value: 'Naistentautien- ja synnytysten hoitotyö', label: 'Naistentautien- ja synnytysten hoitotyö'},
  {value: 'Fysioterapia', label: 'Fysioterapia'},
  {value: 'Kuvantaminen ja sädehoito', label: 'Kuvantaminen ja sädehoito'},
  {value: 'Muu', label: 'Muu'},
];
