import getUniqueAttempts from './getUniqueAttempts';

export default async (callback, locale, ...attempts) => {
  for (const attempt of getUniqueAttempts(locale, attempts)) {
    try {
      const module = await callback(attempt);
      return (
        module.default ||
        // Webpack 3
        module
      );
    } catch {}
  }
};
