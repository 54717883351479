import { Component } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { dissertationOptions, expertiseOptions } from './constants';


export default class MultiSelectField extends Component {

  state = {
    selectedOption: this.props.formData
  };

  colourStylesValid = {
    control: (styles, { isFocused, hasValue }) => ({
      ...styles,
      borderColor: isFocused ? '#66afe9' : 'rgb(209, 209, 209)',
      ':hover': {
        borderColor: '#66afe9',
      },
      boxShadow: isFocused
        ? `inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #66afe9`
        : null
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        backgroundColor:'white',
        ':hover': {
          backgroundColor: '#203a7f',
          color: 'white'
        }
      }),
  };

  colourStylesError = {
    control: (styles, { isFocused, hasValue }) => ({
      ...styles,
      borderColor: '#a94442',
      ':hover': {
        borderColor: '#a94442',
      },
      boxShadow: isFocused
        ? `inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #a94442`
        : null
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        backgroundColor:'white',
        ':hover': {
          backgroundColor: '#203a7f',
          color: 'white'
        }
      }),
  };

  handleChange = (selectedOption) => {
    this.setState({selectedOption}, () => {
      this.props.onChange(this.state.selectedOption);
    });
  };

  render() {
    const { selectedOption } = this.state;
    const { name } = this.props;
    return (
      <div>
        <Select
          options={name === 'dissertationLevel' ? dissertationOptions : expertiseOptions}
          value={selectedOption}
          onChange={this.handleChange}
          isMulti
          styles={_.isEmpty(this.state.selectedOption) ? this.colourStylesError : this.colourStylesValid}
          placeholder={
            name === 'dissertationLevel'
              ? 'Valitse vähintään yksi opinnäytetyön taso'
              : 'Valitse vähintään yksi aihealue'
          }
        />
      </div>
    );
  }
}
