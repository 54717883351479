import {FETCH_RESERVATION} from "../components/actions/fetchReservation";
import {CANCEL_RESERVATION} from "../components/actions/cancelReservation";
import {CLEAR_CANCEL_RESERVATION} from '../components/actions/clearCancelReservation';

export default function(state = {data:{}, removed:false}, action) {
  switch(action.type) {
    case FETCH_RESERVATION:
      return {
        ...state,
        data: action.payload,
        removed: false
      };
    case CANCEL_RESERVATION:
      return {
        ...state,
        data: action.payload,
        removed: true
      };
    case CLEAR_CANCEL_RESERVATION:
      return {
        ...state,
        data: {},
        removed: false
      };
    default:
      return state;
  }
}
