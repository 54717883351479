import {POST_RESERVATION} from "../components/actions/postReservation";
import {CLEAR_POSTED_RESERVATION} from "../components/actions/clearPostedReservation";

export default function(state = {}, action) {
  switch(action.type) {
    case POST_RESERVATION:
      return action.payload;
    case CLEAR_POSTED_RESERVATION:
        return action.payload;
    default:
      return state;
  }
}
