import { ROOT_URL } from '../constants'
import {fetchBuilder} from "./fetchBuilder";

export const SUBMIT_EDITED_TOPIC_AND_RESERVATION = "submit_edited_topic_and_reservation";
export const URL = `${ROOT_URL  }/topics`;


export function submitEditedTopicAndReservation(reservation, id) {

  const request = fetchBuilder(`${URL}/${id}/reservations/${reservation.id}/edit`, "POST", reservation)
    .then(res => res.json()).catch(err => console.log(err));

  return {
    type: SUBMIT_EDITED_TOPIC_AND_RESERVATION,
    payload: request
  }
}
