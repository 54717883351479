import { ROOT_URL } from '../constants'
import {fetchBuilder} from "./fetchBuilder";

export const CANCEL_RESERVATION = "cancel_reservation";

export function cancelReservation(id) {

  const request = fetchBuilder(`${ROOT_URL}/reservations/cancel/${id}`, "POST")
    .then(res => res.ok ? {} : new Error()).catch(err => console.log(err));

  return {
    type: CANCEL_RESERVATION,
    payload: request
  }
}
