import { Component } from 'react';
import { fetchSingleTopic } from './actions/fetchSingleTopic';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Grid, Row } from 'react-bootstrap';
import Header from './Header';

import _ from 'lodash';
import Loader from "./Loader";

class Topic extends Component {

  componentWillMount() {
    window.scrollTo(0, 0);
    document.title = "Aihe - Opinnäytetyöaiheet";
  }

  componentDidMount() {
    this.props.fetchSingleTopic(this.props.id);
  }

  renderContent = () => {
    if(_.isEmpty(this.props.topic)) {
      return <Loader />
    }
    return (
      <div>
        <h2>Tutkimuksen ja kehittämisen painopistealue</h2>
        <p>{this.props.topic.focus}</p>
        <h2>Toimialue</h2>
        {this.props.topic.domain.map((dom, i) => <p key={i}>{dom.name}</p>)}
        <h2>Vastuualue</h2>
        {this.props.topic.areaOfResponsibility.map((aor, i) => <p key={i}>{aor.name}</p>)}
        <h2>Yksikkö</h2>
        {this.props.topic.department.map((dep, i) => <p key={i}>{dep.name}</p>)}
        <h2>Opinnäytetyön taso</h2>
        {this.props.topic.dissertationLevel.map((dis, i) => <p key={i}>{dis.label}</p>)}
        <h2>Aihealue</h2>
        {this.props.topic.expertise.map((exp, i) => <p key={i}>{exp.label}</p>)}
        <h2>Työelämän tarve opinnäytetyölle</h2>
        <p>{this.props.topic.details}</p>
        <h2>Työelämän ehdotus aineistonkeruun menetelmästä</h2>
        <p>{
          this.props.topic.researchMethod !== undefined
          && this.props.topic.researchMethod.length > 0
            ? this.props.topic.researchMethod
            : "-"
        }</p>
        <h2>Työelämän yhteyshenkilö</h2>
        <p>{this.props.topic.officialContact}</p>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Header />
        <Grid className="oppa-content-container">
          <Row>
            <div className="topic-info-container">
              <div className="topic-info-container-inner">
                <div className="topic-info-modal">
                  <div className="topic-info-modal-header">
                    <h1>{this.props.topic.title}</h1>
                  </div>
                  <div className="topic-info-modal-content test-class">
                    {this.renderContent()}
                  </div>
                  <div className="topic-info-modal-buttons">
                    <Link to={{pathname: `/topics/${this.props.id}/reservation`}}>
                      <Button className="btn-oppa-primary btn">Varaa aihe</Button>
                    </Link>
                    <Link to={{pathname: `/`}}>
                      <Button>Takaisin</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { topic: state.topic};
}

export default connect(mapStateToProps, { fetchSingleTopic })(Topic);
