import { combineReducers } from 'redux';
import TopicsReducer from './topics_reducer';
import TopicReducer from './topic_reducer';
import ReservationReducer from './reservation_reducer';
import PostReservationReducer from './post_reservation_reducer';
import PostTopicReducer from './post_topic_reducer';
import TopicReservationReducer from './topic_reservation_reducer';
import SuggestionReducer from './suggestions_reducer';
import CancelReservation from './cancel_reservation_reducer';

const rootReducer = combineReducers({
  topics: TopicsReducer,
  topic: TopicReducer,
  reservation: ReservationReducer,
  reservationPosted: PostReservationReducer,
  topicPosted: PostTopicReducer,
  topicAndReservation: TopicReservationReducer,
  suggestions: SuggestionReducer,
  cancelData: CancelReservation
});

export default rootReducer;
