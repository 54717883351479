import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Modal, Grid, Row, Button, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from './Header';
import Form from 'react-jsonschema-form';
import {TopicSchema, TopicUISchema} from "./schema/TopicSchema";
import { fetchTopicAndReservation } from "./actions/fetchTopicAndReservation";
import { changeTopicAndReservation } from './actions/changeTopicAndReservation';
import { submitEditedTopicAndReservation } from "./actions/submitEditedTopicAndReservation";
import { submitEditedTopic } from './actions/submitEditedTopic';
import { clearRemovedTopic } from "./actions/clearRemovedTopic";
import { Redirect } from 'react-router-dom';

import _ from 'lodash';
import {StudentSchema, StudentUISchema} from "./schema/StudentSchema";
import {TeacherSchema, TeacherUISchema} from "./schema/TeacherSchema";
import { Empty } from './Empty';
import {removeExistingTopic} from "./actions/removeExistingTopic";
import ConfirmActionModal from "./ConfirmActionModal";
import FilterTagInput from "./FilterTagInput";
import Loader from "./Loader";
import {ROOT_URL} from "./constants";
import MultiSelectField from "./MultiSelectField";

const fields = {
  domain: FilterTagInput,
  areaOfResponsibility: FilterTagInput,
  department: FilterTagInput,
  contact: FilterTagInput,
  dissertationLevel: MultiSelectField,
  expertise: MultiSelectField
};

class TopicEditForm extends Component {

  constructor(props) {
    super(props);
    this.topicRef = createRef();
    this.s0F = createRef();
    this.tF = createRef();
    this.s1F = createRef();
    this.s2F = createRef();
  }

  state = {
    show: false,
    showRes: false,
    loading:false,
    prevTopicAndReservation: this.props.topicAndReservation,
    prevTopicRemoved: this.props.removed,
    valid: true,
    error: false
  };

  CustomFieldTemplate = (props) => {
    const {id, classNames, label, help, required, description, children} = props;
    return (
      <div className={"topic-form-tag-field " + classNames}>
        <label className="control-label" htmlFor={id}>{label}{required ? "*" : null}</label>
        {children}
        {description}
      </div>
    );
  };

  componentWillUnmount() {
    this.props.clearRemovedTopic();
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "Aiheen muokkaus - Opinnäytetyöaiheet";
  }

  resetState = () => {
    this.setState({
      error:false
    });
  };

  toggleModal = () => {
    this.setState((prevState) => ({ show: !prevState.show }));
  };

  static getDerivedStateFromProps(props, state) {
    if(_.isEmpty(props.topicAndReservation) && !props.removed && !props.submitted) {
      props.fetchTopicAndReservation(props.id);
      return {
        loading:true
      }
    }
    if(props.topicAndReservation !== state.prevTopicAndReservation) {
      return {
        loading:false,
        prevTopicAndReservation: props.topicAndReservation
      }
    }
    if(props.removed !== state.prevTopicRemoved) {
      return {
        loading:false,
        prevTopicRemoved: props.removed
      }
    }
    return state;
  }

  /*
  * Function for checking form validation state. Validation is checked by going through form references for their
  * error array length. If no errors are found the form is valid for submit.
  * */
  checkValidationState = () => {
    let temp = this.state.valid;
    const topic = this.props.topicAndReservation.topic;
    if(this.topicRef.state.errors.length === 0) {
      if (topic.areaOfResponsibility.length > 0 && topic.domain.length > 0) {
        if(this.s0F !== null && this.s0F.state !== undefined && this.s0F.state.errors.length === 0) {
          if (this.tF !== null && this.tF.state !== undefined && this.tF.state.errors.length === 0) {
            if (this.props.topicAndReservation.students.length === 3) {
              temp = this.s1F !== null && this.s1F.state !== undefined && this.s1F.state.errors.length === 0
                && this.s2F !== null && this.s2F.state !== undefined && this.s2F.state.errors.length === 0
            } else if (this.props.topicAndReservation.students.length === 2) {
              temp = this.s1F !== null && this.s1F.state !== undefined && this.s1F.state.errors.length === 0
            } else {
              temp = true;
            }
          } else {
            temp = false;
          }
        } else {
          temp = true;
        }
      }
    } else {
      temp = false;
    }
    this.setState({valid:temp});
  };

  validateTagFields = (formData, errors) => {
    if(formData.domain !== undefined && formData.domain.length === 0) {
      errors.domain.addError("Field requires atleast one (1) entry.");
    }
    if(formData.areaOfResponsibility !== undefined && formData.areaOfResponsibility.length === 0) {
      errors.areaOfResponsibility.addError("Field requires atleast one (1) entry.");
    }
    if(formData.dissertationLevel !== undefined && formData.dissertationLevel.length === 0) {
      errors.dissertationLevel.addError("Field requires atleast one (1) entry.");
    }
    if(formData.expertise !== undefined && formData.expertise.length === 0) {
      errors.expertise.addError("Field requires atleast one (1) entry.");
    }
    if(formData.contact !== undefined && formData.contact.length === 0) {
      errors.contact.addError("Field requires atleast one (1) entry.");
    }
    return errors;
  };

  handleTopicChange = (form) => {
    this.props.changeTopicAndReservation({...this.props.topicAndReservation, topic:form.formData});
    this.checkValidationState();
  };

  handleTeacherChange = (form) => {
    this.props.changeTopicAndReservation({...this.props.topicAndReservation, teacher:form.formData});
    this.checkValidationState();
  };

  handleStudentChange = (form) => {
    const temp = [...this.props.topicAndReservation.students];
    temp[Number(form.idSchema.$id)] = form.formData;
    this.props.changeTopicAndReservation({...this.props.topicAndReservation, students:temp});
    this.checkValidationState();
  };

  handleReservationRemoval = () => {
    fetch(`${ROOT_URL}/reservations/delete/${this.props.topicAndReservation.reservationId}`, {
      method: 'POST',
      mode: 'cors'
    }).then(res => {
      if(res.ok) {
        this.setState((prevState) => ({
            showRes: !prevState.showRes
          }), () => {
          this.props.topicAndReservation.topic.reservationStatus = false;
          this.props.changeTopicAndReservation({"topic":this.props.topicAndReservation.topic});
        })
      } else {
        return new Error();
      }
    }).catch(err => this.setState({error:true}));
  };

  /*
  * Function for submitting an updated form. If the topic has a reservation it updates both, otherwise only topic.
  * */
  handleFormSubmit = (form) => {
    if(this.state.valid) {
      this.setState({loading:true}, () => {
        if(_.has(this.props.topicAndReservation, 'students')) {
          this.props.submitEditedTopicAndReservation(
            {
              "students": this.props.topicAndReservation.students,
              "teacher": this.props.topicAndReservation.teacher,
              "topic":this.props.topicAndReservation.topic,
              "id": this.props.topicAndReservation.reservationId
            },
            this.props.id
          );
        } else {
          this.props.submitEditedTopic(this.props.topicAndReservation.topic, this.props.topicAndReservation.topic.id);
        }
      });
    }
  };

  deleteTopic = () => {
    this.setState({
      loading:true
    });
    this.props.removeExistingTopic(this.props.id);
  };

  conditionalRender = () => {
    if(_.has(this.props.topicAndReservation, 'students') && this.props.topicAndReservation.students.length > 1) {
      return (
        <div>
          <Form
            ref={(form) => {this.topicRef = form;}}
            FieldTemplate={this.CustomFieldTemplate}
            liveValidate
            validate={this.validateTagFields}
            showErrorList={false}
            schema={TopicSchema}
            uiSchema={TopicUISchema}
            fields={fields}
            formData={this.props.topicAndReservation.topic}
            onChange={this.handleTopicChange}
            onSubmit={this.handleFormSubmit}
          >
            <Empty />
          </Form>
          <div className="topic-info-modal-header">
            <Button onClick={() => this.setState({showRes:true})} className="btn-oppa-danger btn">Poista varaus</Button>
            <h2>Opiskelija</h2>
          </div>
          <FormGroup>
            <hr />
            <Form
              ref={(form) => {this.s0F=form;}}
              FieldTemplate={this.CustomFieldTemplate}
              liveValidate
              showErrorList={false}
              schema={StudentSchema}
              uiSchema={StudentUISchema}
              onChange={this.handleStudentChange}
              idPrefix="0"
              formData={this.props.topicAndReservation.students[0]}
            >
              <Empty />
            </Form>
          </FormGroup>
          {this.props.topicAndReservation.students.map((student, index) => {
            if(index !== 0) {
              return (
                <FormGroup key={index}>
                  <h2>Opiskelija</h2>
                  <hr />
                  <Form
                    ref={(form) => {index === 1 ? this.s1F=form: this.s2F=form;}}
                    FieldTemplate={this.CustomFieldTemplate}
                    liveValidate
                    showErrorList={false}
                    schema={StudentSchema}
                    uiSchema={StudentUISchema}
                    onChange={this.handleStudentChange}
                    idPrefix={`${index}`}
                    formData={this.props.topicAndReservation.students[index]}
                  >
                    <Empty />
                  </Form>
                </FormGroup>
              );
            }
          })}
          <FormGroup>
            <h2>Opettaja</h2>
            <hr />
            <Form
              ref={(form) => {this.tF=form;}}
              FieldTemplate={this.CustomFieldTemplate}
              liveValidate
              showErrorList={false}
              schema={TeacherSchema}
              uiSchema={TeacherUISchema}
              onChange={this.handleTeacherChange}
              formData={this.props.topicAndReservation.teacher}
            >
              <Empty />
            </Form>
          </FormGroup>
        </div>
      );
    } else if (_.has(this.props.topicAndReservation, 'students') && this.props.topicAndReservation.students.length === 1) {
      return (
        <div>
          <Form
            ref={(form) => {this.topicRef=form;}}
            FieldTemplate={this.CustomFieldTemplate}
            liveValidate
            validate={this.validateTagFields}
            showErrorList={false}
            schema={TopicSchema}
            uiSchema={TopicUISchema}
            fields={fields}
            formData={this.props.topicAndReservation.topic}
            onChange={this.handleTopicChange}
          >
            <Empty />
          </Form>
          <div className="topic-info-modal-header">
            <Button onClick={() => this.setState({showRes:true})} className="btn-oppa-danger btn">Poista varaus</Button>
            <h2>Opiskelija</h2>
          </div>
          <FormGroup>
            <hr />
            <Form
              ref={(form) => {this.s0F=form;}}
              FieldTemplate={this.CustomFieldTemplate}
              liveValidate
              showErrorList={false}
              schema={StudentSchema}
              uiSchema={StudentUISchema}
              onChange={this.handleStudentChange}
              idPrefix="0"
              formData={this.props.topicAndReservation.students[0]}
            >
              <Empty />
            </Form>
          </FormGroup>
          <FormGroup>
            <h2>Opettaja</h2>
            <hr />
            <Form
              ref={(form) => {this.tF=form;}}
              FieldTemplate={this.CustomFieldTemplate}
              liveValidate
              showErrorList={false}
              schema={TeacherSchema}
              uiSchema={TeacherUISchema}
              onChange={this.handleTeacherChange}
              formData={this.props.topicAndReservation.teacher}
            >
              <Empty />
            </Form>
          </FormGroup>
        </div>
      );

    }
      return (
        <Form
          ref={(form) => {this.topicRef=form;}}
          FieldTemplate={this.CustomFieldTemplate}
          liveValidate
          validate={this.validateTagFields}
          showErrorList={false}
          schema={TopicSchema}
          uiSchema={TopicUISchema}
          fields={fields}
          formData={this.props.topicAndReservation.topic}
          onChange={this.handleTopicChange}
        >
          <Empty />
        </Form>
      );

  };

  render() {

    if(this.state.error) {
      window.scrollTo(0, 0);
      return (
        <div>
          <Header />
          <Modal onExited={this.resetState} show={this.props.error} onHide={() => this.setState({error:false})}>
            <div>
              <Modal.Header>
                <Modal.Title>Tapahtui virhe</Modal.Title>
              </Modal.Header>
              <Modal.Body className="topic-list-modal-body">
                <p>Varauksen poistaminen epäonnistui.</p>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => this.setState({error:false})}>Sulje</Button>
              </Modal.Footer>
            </div>
          </Modal>
        </div>
      );
    }

    if(this.state.loading) {
      return (
        <div>
          <Header />
          <Loader />
        </div>
      );
    }

    if(this.props.removed) {
      return(
        <Redirect
          push
          to={{
          pathname: `/`
        }}
        />
      );
    }

    if(this.props.submitted && _.has(this.props.data, "error")) {
      window.scrollTo(0, 0);
      return (
        <div>
          <Header />
          <Grid className="oppa-content-container">
            <Row>
              <div className="topic-info-container">
                <div className="topic-info-container-inner">
                  <div className="topic-info-modal">
                    <div className="topic-info-modal-header">
                      <h4>Tallennus epäonnistui.</h4>
                    </div>
                    <div className="topic-info-modal-content">
                      <p>Muutosten tallennus palvelimelle epäonnistui.</p>
                    </div>
                    <div className="topic-info-modal-buttons">
                      <Link to={{pathname: '/'}}>
                        <Button className="btn-oppa-primary btn">Takaisin etusivulle</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Grid>
        </div>
      );
    }

    if(this.props.submitted && !_.has(this.props.data, "error")) {
      window.scrollTo(0, 0);
      return (
        <div>
          <Header />
          <Grid className="oppa-content-container">
            <Row>
              <div className="topic-info-container">
                <div className="topic-info-container-inner">
                  <div className="topic-info-modal">
                    <div className="topic-info-modal-header">
                      <h4>Muutos tallennettu!</h4>
                    </div>
                    <div className="topic-info-modal-content">
                      <p>Tekemäsi muutokset on tallennettu tietokantaan.</p>
                    </div>
                    <div className="topic-info-modal-buttons">
                      <Link to={{pathname: '/'}}>
                        <Button className="btn-oppa-primary btn">Takaisin etusivulle</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Grid>
        </div>
      );
    }
    if(!_.isEmpty(this.props.topicAndReservation)) {
      return (
        <div>
          <Header />
          <Grid className="oppa-content-container">
            <Row>
              <div className="topic-info-container">
                <div className="topic-info-container-inner">
                  <div className="topic-info-modal">
                    <div className="topic-info-modal-header">
                      <h1>Aiheen tiedot</h1>
                      <Button onClick={this.toggleModal} className="btn-oppa-danger btn">Poista aihe</Button>
                    </div>
                    <div className="topic-info-modal-content">
                      {this.conditionalRender()}
                    </div>
                    <div className="topic-info-modal-buttons">
                      <Button onClick={this.handleFormSubmit} disabled={!this.state.valid} className="btn-oppa-success btn">Tallenna</Button>
                      <Link to={{pathname: `/`}}>
                        <Button>Takaisin</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <ConfirmActionModal
                toggleModal={() => {this.setState((prevState) => ({showRes:!prevState.showRes}))}}
                modalAction={this.handleReservationRemoval}
                title="Olet poistamassa aiheen varauksen!"
                message="Haluatko varmasti poistaa varauksen?"
                action="Poista varaus"
                show={this.state.showRes}
              />
              <ConfirmActionModal
                toggleModal={this.toggleModal}
                modalAction={this.deleteTopic}
                title="Olet poistamassa aiheen!"
                message="Haluatko varmasti poistaa aiheen?"
                action="Poista aihe"
                show={this.state.show}
              />
            </Row>
          </Grid>
        </div>
      );
    }
  }
}



function mapStateToProps(state) {
  return {
    topicAndReservation: state.topicAndReservation.data,
    submitted: state.topicAndReservation.submitted,
    removed: state.topicAndReservation.removed,
  }
}

export default connect(mapStateToProps, {
  fetchTopicAndReservation,
  changeTopicAndReservation,
  submitEditedTopic,
  submitEditedTopicAndReservation,
  removeExistingTopic,
  clearRemovedTopic
})(TopicEditForm);
