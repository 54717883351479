export const SAVE_RESERVATION = "save_reservation";
export function saveReservation(topic_id, reservation, students) {
  return {
    type: SAVE_RESERVATION,
    payload: {
      id: topic_id,
      reservation: reservation,
      students: students
    }
  }
}
