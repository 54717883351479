import { Pagination } from 'react-bootstrap';
import { Component } from 'react';
import _ from 'lodash';

export default class ListPagination extends Component {
  calculatePagination = () => {
    // NEW VERSION
    if(!_.isEmpty(this.props.topics)) {
      const { active } = this.props;
      let arr = [];

      // Check if we need more than one page
      if(this.props.topics.length > 5) {
        // Check if we need more than 5 pages
        // Return simple list of items
        arr = [];
        for(let i = 1; i <= this.props.topics.length / 5; i++) {
          arr.push(<Pagination.Item onClick={this.props.onChange} key={i} active={i === active}>{i}</Pagination.Item>)
        }
        if(arr.length < this.props.topics.length / 5 && this.props.topics.length % 5 !== 0) {
          arr.push(
            <Pagination.Item
              key={(arr.length + 1)}
              active={arr.length + 1 === active}
              onClick={this.props.onChange}
            >
              {arr.length + 1}
            </Pagination.Item>)
        }

      }
      return arr.map((page, index) => {
        if(active < 3) {
          return index < 5 ? page : null
        }
          return index + 1 >= active - 2 && index + 1 <= active + 2 ? page : null

      })
    }
  };

  render() {
    return (
      <div className="pagination-parent">
        {this.props.topics.length > 5 && (
          <Pagination bsSize="medium">
            <Pagination.First onClick={this.props.first} />
            <Pagination.Prev onClick={this.props.prev}/>
            {this.calculatePagination()}
            <Pagination.Next onClick={this.props.next}/>
            <Pagination.Last onClick={this.props.last}/>
          </Pagination>
        )}
      </div>
    );
  }
}
