import {POST_TOPIC} from "../components/actions/postTopic";
import {CLEAR_TOPIC} from "../components/actions/clearTopic";


export default function(state = {}, action) {
  switch(action.type) {
    case POST_TOPIC:
      return action.payload;
    case CLEAR_TOPIC:
      return action.payload;
    default:
      return state;
  }
}
