import { Component } from 'react';
import { Checkbox, Button, Grid, Row } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { connect }from 'react-redux';
import { postReservation } from "../actions/postReservation";
import { clearPostedReservation } from "../actions/clearPostedReservation";
import { clearReservation } from "../actions/clearReservation";
import Header from '../Header';
import _ from 'lodash';
import Loader from "../Loader";


class ReservationResult extends Component {

  state = {
    loading:false,
    prevReservationPosted: this.props.reservationPosted,
    tosAgreement: true,
    backNavigationClicked: false
  };

  handleReservationSubmit = () => {
    this.setState({loading:true}, () => {
      this.props.postReservation(this.props.id, {
        "topicId":this.props.reservation.reservation.topicId,
        "teacher": this.props.reservation.reservation.teacher,
      }, this.props.reservation.students);
    });
  };

  handleChecked = (e) => {
    this.setState((prevState) => ({ tosAgreement : !prevState.tosAgreement }));
  };

  static getDerivedStateFromProps(props, state) {
    if(props.reservationPosted !== state.prevReservationPosted) {
      return {
        loading: false,
        prevReservationPosted: props.reservationPosted
      }
    }
    return null;
  }

  componentDidMount() {
    document.title = "Varauksen yhteenveto - Opinnäytetyöaiheet";
  }

  componentWillUnmount() {
    if(!_.isEmpty(this.props.reservationPosted) || _.has(this.props.reservationPosted, 'error')) {
      this.props.clearPostedReservation();
      this.props.clearReservation();
    }
    window.scrollTo(0, 0);
  }

  handleBackNavigation = () => {
    this.setState({backNavigationClicked: true});
  };

  render() {
    let { backNavigationClicked } = this.state;


    if(this.state.loading) {
      return (
        <div>
          <Header />
          <Loader />
        </div>
      );
    }

    if(backNavigationClicked) {
      return (
        <Redirect
          push
          to={{pathname: `/topics/${this.props.id}/reservation`}}
        />
      );
    }

    if(_.has(this.props.reservationPosted, 'error') && this.props.reservationPosted.error !== "409") {
      window.scrollTo(0, 0);
      return(
        <div>
          <Header />
          <Grid>
            <Row>
              <div className="topic-info-container">
                <div className="topic-info-container-inner">
                  <div className="topic-info-modal">
                    <div className="topic-info-modal-header">
                      <h4>Varaus epäonnistui!</h4>
                    </div>
                    <div className="topic-info-modal-content">
                      <p>Palveluun ei juuri nyt saada yhteyttä. Yritä myöhemmin uudelleen.</p>
                    </div>
                    <div className="topic-info-modal-buttons">
                      <Link to={{pathname: '/'}}>
                        <Button className="btn-oppa-primary btn">Takaisin etusivulle</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Grid>
        </div>
      );
    }

    if(_.has(this.props.reservationPosted, 'error') && this.props.reservationPosted.error === "409") {
      window.scrollTo(0, 0);
      return(
        <div>
          <Header />
          <Grid>
            <Row>
              <div className="topic-info-container">
                <div className="topic-info-container-inner">
                  <div className="topic-info-modal">
                    <div className="topic-info-modal-header">
                      <h4>Varaus epäonnistui!</h4>
                    </div>
                    <div className="topic-info-modal-content">
                      <p>Yhdellä tai useammalla varauksen tehneellä henkilöllä on jo hänen sähköpostillaan varattu aihe!</p>
                    </div>
                    <div className="topic-info-modal-buttons">
                      <Link to={{pathname: '/'}}>
                        <Button className="btn-oppa-primary btn">Takaisin etusivulle</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Grid>
        </div>
      );
    }
    if(!_.isEmpty(this.props.reservationPosted) && !_.has(this.props.reservationPosted, 'error')) {
      window.scrollTo(0, 0);
      return (
        <div>
          <Header />
          <Grid className="oppa-content-container">
            <Row>
              <div className="topic-info-container">
                <div className="topic-info-container-inner">
                  <div className="topic-info-modal">
                    <div className="topic-info-modal-header">
                      <h4>Kiitos varauksestasi!</h4>
                    </div>
                    <div className="topic-info-modal-content">
                      <p>Kun varauksesi on oppilaitoksesi puolesta hyväksytty, vastuuopettajasi on yhteydessä työelämän yhteyshenkilöön työelämäpalaverista sopimiseksi.</p>
                      <p>Opinnäyteyhteistyön tulee käynnistyä 6 viikon sisällä aiheen varaamisesta. Mikäli näin ei tapahdu, aihe tulee palauttaa takaisin aihepankkiin peruuttamalla varaus aihepankin etusivulta.
                      </p>
                    </div>
                    <div className="topic-info-modal-buttons">
                      <Link to={{pathname: '/'}}>
                        <Button className="btn-oppa-primary btn">Takaisin etusivulle</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Grid>
        </div>
      );
    }
    if(!_.isEmpty(this.props.reservation)) {
      return(
        <div>
          <Header />
          <Grid className="oppa-content-container">
            <Row>
              <div className="topic-info-container">
                <div className="topic-info-container-inner">
                  <div className="topic-info-modal">
                    <div className="topic-info-modal-header">
                      <h1>{this.props.topic.title}</h1>
                    </div>
                    <div className="topic-info-modal-content">
                      <h2>Tutkimuksen ja kehittämisen painopistealue</h2>
                      <p>{this.props.topic.focus}</p>
                      <h2>Toimialue</h2>
                      {this.props.topic.domain.map((dom, i) => <p key={i}>{dom.name}</p>)}
                      <h2>Vastuualue</h2>
                      {this.props.topic.areaOfResponsibility.map((aor, i) => <p key={i}>{aor.name}</p>)}
                      <h2>Yksikkö</h2>
                      {this.props.topic.department.map((dep, i) => <p key={i}>{dep.name}</p>)}
                      <h2>Opinnäytetyön taso</h2>
                      {this.props.topic.dissertationLevel.map((dis, i) => <p key={i}>{dis.label}</p>)}
                      <h2>Aihealue</h2>
                      {this.props.topic.expertise.map((exp, i) => <p key={i}>{exp.label}</p>)}
                      <h2>Työelämän tarve opinnäytetyölle</h2>
                      <p>{this.props.topic.details}</p>
                      <h2>Työelämän ehdotus aineistonkeruun menetelmästä</h2>
                      <p>{this.props.topic.researchMethod}</p>
                      <h2>Työelämän yhteyshenkilö</h2>
                      <p>{this.props.topic.officialContact}</p>
                      <hr />
                      <h2>Varauksen tiedot</h2>
                      {this.props.reservation.students.map((student, index) => {
                        if(student.firstName.length > 0) {
                          return (<div key={index}>
                            <h2>Opiskelija {index + 1}</h2>
                            <p>{student.firstName} {student.lastName}</p>
                            <p>{student.email}</p>
                            <p>{student.groupId}</p>
                            <p>{student.fieldOfStudy}</p>
                          </div>);
                        }
                      })}
                      {!_.isEmpty(this.props.reservation.reservation.teacher)
                        ? this.props.reservation.reservation.teacher.firstName !== undefined
                          && this.props.reservation.reservation.teacher.firstName !== null
                          && this.props.reservation.reservation.teacher.firstName.length > 0
                          ? <div>
                            <h2>Ohjaava opettaja / oppilaitoksen yhteyshenkilö</h2>
                            <p>{this.props.reservation.reservation.teacher.firstName} {this.props.reservation.reservation.teacher.lastName}</p>
                            <p>{this.props.reservation.reservation.teacher.email}</p>
                            </div>
                          :null
                        : null
                      }

                      <hr />

                      <h2>Tietosuojaseloste</h2>

                      <Checkbox onChange={this.handleChecked}>
                        <p>Olen varmistanut, että muut varaukseen liittyvät opiskelijat ja työn ohjaaja ovat tietoisia, mitä tietoja heistä tallennetaan tähän järjestelmään sekä miten niitä käytetään.</p>

                        <Link to={
                          {
                            pathname: `/tietosuojaseloste/`,
                            state: {
                              fromFooter: false,
                              id: this.props.id
                            }
                          }
                        }>
                          Tietosuojaseloste
                        </Link>


                      </Checkbox>

                    </div>
                    <div className="topic-info-modal-buttons-fix">
                      <div>
                        <Button onClick={this.handleBackNavigation}>Takaisin</Button>
                        <Button
                          disabled={this.state.tosAgreement}
                          onClick={this.handleReservationSubmit}
                          className="btn-oppa-primary btn">Lähetä varaus</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Grid>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    reservation: state.reservation,
    topic: state.topic,
    reservationPosted: state.reservationPosted
  }
}

export default connect(mapStateToProps, { postReservation, clearReservation, clearPostedReservation })(ReservationResult);
