import { Component } from 'react';
import { Grid } from 'react-bootstrap';
import logo from './../images/tays-h50.png';
import { Link } from 'react-router-dom';

export default class Header extends Component {
  render() {
    return (
      <div className="oppa-header">
        <Grid>
          <Link to={{pathname: `/`}}>
            <img style={{margin: "10px"}} src={logo} alt="Opinnäytetyöaihepankki etusivu" />
          </Link>
        </Grid>
      </div>
    );
  }
}
