import { Component } from 'react';
import ReactTags from 'react-tag-autocomplete';
import _ from 'lodash';


export default class FilterBarTagField extends Component {

  replacementClassNames = {
    root: 'filter-react-tags',
    rootFocused: 'filter-is-focused',
    selected: 'filter-react-tags__selected',
    selectedTag: 'filter-react-tags__selected-tag',
    selectedTagName: 'filter-react-tags__selected-tag-name',
    search: 'filter-react-tags__search',
    searchInput: 'filter-react-tags__search-input',
    suggestions: 'filter-react-tags__suggestions',
    suggestionActive: 'filter-is-active',
    suggestionDisabled: 'filter-is-disabled'
  };


  handleAddition = (tag) => {
    let tags = _.isEmpty(this.props.tags) ? [tag] : [...this.props.tags, tag];
    this.props.onChange(tags, this.props.placeholder);
  };

  handleDelete = (i) => {
    let tags = [...this.props.tags];
    tags.splice(i, 1);
    this.props.onChange(tags, this.props.placeholder);
  };

  render() {
    return (
      <ReactTags
        tags={this.props.tags}
        suggestions={this.props.suggestions}
        handleDelete={this.handleDelete}
        handleAddition={this.handleAddition}
        delimiters={[9,13]}
        placeholder={this.props.placeholder}
        allowNew={true}
        allowBackspace={false}
        autofocus={false}
      />
    );
  }
}
