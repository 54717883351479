import { Component } from 'react';
import { Button, Grid, Row } from 'react-bootstrap';
import Header from './Header';


export default class DataPrivacy extends Component {

  state = {
    pathname: ''
  };

  componentWillMount() {
    window.scrollTo(0, 0);
    document.title = "Tietosuojaseloste - Opinnäytetyöaiheet";
  }

  navigateBackOnClick = () => {
    this.props.nav.history.goBack();
  };

  renderContent = () => {
    return (
      <div>
        <ol>
          <li>
            <h2>Opinnäytetyöaiheiden varausrekisteri</h2></li>
          <li>
            <h3>Rekisterin nimi</h3>
            <p>Opinnäytetyöaiheiden varausrekisteri</p>
          </li>
          <li>
            <h3>Rekisterinpitäjä</h3>
            <p>Pirkanmaan sairaanhoitopiiri</p>
            <p>PL 2000, 33521 Tampere</p>
            <p>puhelinvaihde 03 311 611</p>
          </li>
          <li>
            <h3>Rekisterin vastuuhenkilö</h3>
            <p>Opetusylihoitaja</p>
          </li>
          <li>
            <h3>Rekisterin yhteyshenkilö</h3>
            <p>Tampereen yliopistollinen sairaala</p>
            <p>Palvelukeskus/Osaamisen kehittämisyksikkö</p>
            <p>PL 2000, 33521 Tampere</p>
            <p>puhelin 03 311 69730</p>
          </li>
          <li>
            <h3>Henkilötietojen käsittelyn tarkoitus</h3>
            <p>Opinnäytetyöaiheiden varausrekisterin tietoja käytetään opinnäytetyöprosessin koordinointiin:</p>
            <ul>
              <li>opinnäytetyöaiheiden tarjoaminen,</li>
              <li>opinnäytetyöaiheen valinta ja</li>
              <li>opinnäyteyhteistyön käynnistäminen</li>
            </ul>
          </li>
          <li>
            <h3>Henkilötietojen käsittelyn oikeusperuste</h3>
            <p>Henkilötietojen käsittely perustuu järjestelmän käyttäjän antamaan suostumukseen.</p>
          </li>
          <li>
            <h3>Henkilötietoryhmät</h3>
            <p>Nimi, sähköpostiosoite, oppilaitos, oppilaitoksen ryhmä</p>
          </li>
          <li>
            <h3>Säännönmukaiset tietolähteet</h3>
            <p>Opinnäytetyöaiheen opiskelijoiden ja ohjaavan opettajan/oppilaitoksen yhteyshenkilön tiedot saadaan suoraan varauksen tekevältä opiskelijalta.</p>
          </li>
          <li>
            <h3>Profilointi tai automaattinen päätöksenteko</h3>
            <p>Henkilörekisterin tietojen perusteella ei suoriteta automaattista päätöksentekoa tai profilointia.</p>
          </li>
          <li>
            <h3>Tietojen säännönmukaiset luovutukset</h3>
            <p>Ei säännönmukaista tietojen luovutusta.</p>
          </li>
          <li>
            <h3>Tietojen siirto EU:n tai ETA:n ulkopuolelle</h3>
            <p>Tietoja ei siirretä.</p>
          </li>
          <li>
            <h3>Henkilötietojen säilytysajan määrittäminen</h3>
            <p>Tietoja säilytetään 2 vuotta varauksen tekoajankohdasta lukien.</p>
          </li>
        </ol>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Header />
        <Grid className="oppa-content-container">
          <Row>
            <div className="topic-info-container">
              <div className="topic-info-container-inner">
                <div className="topic-info-modal">
                  <div className="topic-info-modal-header">
                    <h1>Tietosuojaseloste</h1>
                  </div>
                  <div className="topic-info-modal-content test-class">
                    {this.renderContent()}
                  </div>
                  <div className="topic-info-modal-buttons">
                      <Button onClick={this.navigateBackOnClick}>Takaisin</Button>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Grid>
      </div>
    );
  }
}
