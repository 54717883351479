import { ROOT_URL } from '../constants'
import {fetchBuilder} from "./fetchBuilder";

const URL = `${ROOT_URL  }/topics/`;
export const FETCH_TOPIC = "fetch_topic";

export function fetchSingleTopic(id) {

  const request = fetchBuilder(URL + id, "GET")
    .then(res => {
      if(res.status === 204) {
        return {};
      }
      return res.json();
    }).catch(err => console.log(err));

  return {
    type: FETCH_TOPIC,
    payload: request
  }

}
