import { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { object } from 'prop-types';
import IntlProviderWrapper from './IntlProviderWrapper';
import { LOCALE, PATH_PREFIX } from '..';

const instances = new Set();

function localStorageListener({ key }) {
  if (key === LOCALE) {
    instances.forEach(instance => instance.forceUpdate());
  }
}

export default class Localize extends Component {
  static contextTypes = { router: object };
  componentWillMount() {
    if (!instances.size) {
      window.addEventListener('storage', localStorageListener);
    }
    instances.add(this);
  }
  componentWillUnmount() {
    instances.delete(this);
    if (!instances.size) {
      window.removeEventListener('storage', localStorageListener);
    }
  }
  render() {
    const {
      props: { defaultLocale = 'en-US', locale, ...props },
      context: { router }
    } = this;

    const app = (
      <IntlProviderWrapper
        {...props}
        key="app"
        locale={
          locale ||
          localStorage.getItem(LOCALE) ||
          navigator.language ||
          navigator.userLanguage ||
          defaultLocale
        }
      />
    );

    return router ? (
      // If router is present, watch routes for locale change.
      <Route
        path={`${PATH_PREFIX}:locale`}
        children={({ match, location }) => [
          app,
          match &&
            do {
              const { params: { locale } = {} } = match;
              localStorage.setItem(LOCALE, locale);
              <Redirect
                key="redirect"
                to={{
                  ...location,
                  pathname: location.pathname.slice(
                    PATH_PREFIX.length + locale.length
                  )
                }}
              />;
            }
        ]}
      />
    ) : (
      app
    );
  }
}
