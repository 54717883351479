import {FETCH_TOPIC_AND_RESERVATION} from "../components/actions/fetchTopicAndReservation";
import {CHANGE_TOPIC_AND_RESERVATION} from "../components/actions/changeTopicAndReservation";
import {SUBMIT_EDITED_TOPIC_AND_RESERVATION} from "../components/actions/submitEditedTopicAndReservation";
import {SUBMIT_EDITED_TOPIC} from "../components/actions/submitEditedTopic";
import {REMOVE_EXISTING_TOPIC} from "../components/actions/removeExistingTopic";
import {CLEAR_REMOVED_TOPIC} from "../components/actions/clearRemovedTopic";

export default function(state = {data:{}, submitted:false, removed:false}, action) {
  switch(action.type) {
    case FETCH_TOPIC_AND_RESERVATION:
      return {
        ...state,
        data: action.payload,
        submitted: false,
        removed:false
      };
    case CHANGE_TOPIC_AND_RESERVATION:
      return {
        ...state,
        data: action.payload,
        submitted: false
      };
    case SUBMIT_EDITED_TOPIC_AND_RESERVATION:
      return {
        ...state,
        data: action.payload,
        submitted: true
      };
    case SUBMIT_EDITED_TOPIC:
      return {
        ...state,
        data: {},
        submitted: true
      };
    case REMOVE_EXISTING_TOPIC:
      return {
        ...state,
        data:{},
        submitted:false,
        removed:true
      };
    case CLEAR_REMOVED_TOPIC:
      return {
        ...state,
        data:{},
        submitted:false,
        removed:false
      };
    default:
      return state;
  }
}

