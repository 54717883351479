export const StudentSchema = {
  "type": "object",
  "required": [
    "firstName",
    "lastName",
    "email",
    "fieldOfStudy",
    "groupId"
  ],
  "properties": {
    "firstName": {
      "type": "string",
      "title": "Etunimi"
    },
    "lastName": {
      "type":"string",
      "title": "Sukunimi"
    },
    "email": {
      "type": "string",
      "title": "Opiskelijan sähköpostiosoite",
      "pattern": "\\S+@\\S+\\.\\S+"
    },
    "groupId": {
      "type": "string",
      "title": "Ryhmätunnus"
    },
    "fieldOfStudy": {
      "type": "string",
      "title": "Oppilaitos"
    }
  }
};

export const StudentUISchema = {
  "firstName": {
    "classNames": "col-sm-6 del-padding"
  },
  "lastName": {
    "classNames": "col-sm-6 del-padding"
  },
  "email": {
    "classNames": "col-sm-6 del-padding",
    "ui:options": {
      "inputType": "email"
    }
  },
  "fieldOfStudy": {
    "classNames": "col-sm-6 del-padding"
  },
  "groupId": {
    "classNames": "col-sm-6 del-padding"
  }
};
