import { Component } from 'react';
import { Row, Button, FormControl, ControlLabel, Nav, NavDropdown, MenuItem, Panel} from 'react-bootstrap';
import FilterBarTagField from './FilterBarTagField';
import MultiSelectFilter from "./MultiSelectFilter";


export default class FilterBarExtended extends Component {

  state = {
    tags: {
      domain:[],
      areaOfResponsibility:[],
      department:[]
    },
    open: false
  };

  handleTagChange = (tag, child) => {
    let tags;
    switch(child) {
      case "Toimialue":
        tags = {...this.state.tags, domain:tag};
        this.setState({tags}, () => {
          this.props.onChange(this.state.tags);
        });
        return;
      case "Vastuualue":
        tags = {...this.state.tags, areaOfResponsibility:tag};
        this.setState({tags}, () => {
          this.props.onChange(this.state.tags);
        });
        return;
      case "Yksikkö":
        tags = {...this.state.tags, department:tag};
        this.setState({tags}, () => {
          this.props.onChange(this.state.tags);
        });

    }

  };

  handleSelectToParent = (selected, name) => {
    this.props.handleSelect(selected, name);
  };

  render() {
    const {domain = [], areaOfResponsibility = [], department = []} = this.state.tags;
    return(
      <div>
        <Row className="filter-bar-row">
          <div className="filter-bar">
            <div className="filter-bar-filters">

              <FormControl placeholder="Haku" onChange={this.props.handleSearch} style={{maxWidth:`${150}px`}} type="text" />
              <div className="filter-bar-filters-wrapper">
                <MultiSelectFilter
                  isFilter={true}
                  name="expertise"
                  onChange={this.handleSelectToParent}
                  data={this.props.expertise}
                />
              </div>
              <div className="filter-bar-filters-wrapper">
                <MultiSelectFilter
                  isFilter={true}
                  name="dissertationLevel"
                  onChange={this.handleSelectToParent}
                  data={this.props.dissertation}
                />
              </div>
              <Button className="btn-oppa-primary btn" onClick={() => this.setState({open: !this.state.open})}>Näytä lisäsuodattimet</Button>
            </div>
          </div>
        </Row>
        <Row>
          <Panel className={this.state.open ? "panel-open" : "panel-closed"} onToggle={() =>{}} expanded={this.state.open}>
            <Panel.Collapse>
              <Panel.Body>
                <div className="filter-bar-extended-tag-wrapper">
                  <div>
                    <FilterBarTagField
                      suggestions={this.props.suggestions.domain}
                      tags={domain}
                      onChange={this.handleTagChange}
                      placeholder="Toimialue"
                    />
                  </div>
                  <div>
                    <FilterBarTagField
                      suggestions={this.props.suggestions.areaOfResponsibility}
                      tags={areaOfResponsibility}
                      onChange={this.handleTagChange}
                      placeholder="Vastuualue"
                    />
                  </div>
                  <div>
                    <FilterBarTagField
                      suggestions={this.props.suggestions.department}
                      tags={department}
                      onChange={this.handleTagChange}
                      placeholder="Yksikkö"
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel.Collapse>
          </Panel>
        </Row>
      </div>
    );
  }
}
