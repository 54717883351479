import { ROOT_URL } from '../constants';
import {fetchBuilder} from "./fetchBuilder";

export const FETCH_TOPIC_AND_RESERVATION = 'fetch_topic_and_reservation';

export function fetchTopicAndReservation(id) {

  const request = fetchBuilder(`${ROOT_URL}/topics/${id}/edit`, "GET")
    .then(res => res.json())
    .catch(err => console.log(err));

  return {
    type: FETCH_TOPIC_AND_RESERVATION,
    payload: request
  };
}
