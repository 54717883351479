import { injectIntl } from 'react-intl';
import { createElement } from 'react';
import { Route } from 'react-router-dom';
import { PATH_PREFIX } from '.';

const Selector = ({ intl, locales, ...propsCommon }) => (
  <Route
    children={({ history: { createHref, location, replace } }) =>
      locales.map(
        ({ locale, children, ...remainingProps }) =>
          do {
            const to = {
              ...location,
              pathname: `${PATH_PREFIX}${locale}${location.pathname}`
            };
            const props = {
              ...propsCommon,
              ...remainingProps,
              key: locale
            };
            // Links to other locales also get href & onClick props.
            if (intl.locale !== locale) {
              Object.assign(props, {
                href: createHref(to),
                onClick(event) {
                  event.preventDefault();
                  replace(to);
                }
              });
            }
            typeof children === 'function'
              ? children(props)
              : createElement(
                  intl.locale === locale ? 'span' : 'a',
                  props,
                  children
                );
          }
      )
    }
  />
);

export default Selector |> injectIntl;
