import { Component } from 'react';
import {Row} from 'react-bootstrap';
import {RESERVATION_GUIDE_URL} from "./constants";

export default class TopicListTabTitle extends Component {
  render() {
    return (
      <Row className="topic-list-title">
        <h1>{this.props.tab === 1 ? "Vapaat aiheet" : "Varatut aiheet"}</h1>
      </Row>
    );
  }
}
