import _ from 'lodash';

export function fetchBuilder(url, method, data = {}) {
  return fetch(url, {
    method: method,
    mode: "cors",
    credentials: "same-origin",
    body: !_.isEmpty(data) ? JSON.stringify(data) : null,
    headers: {
      'Content-Type':'application/json;charset=UTF-8'
    }
  });
}
