export const TeacherSchema = {
  type: 'object',
  required: [
    "firstName",
    "lastName",
    "email"
  ],
  properties: {
    firstName: {
      type: 'string',
      title: 'Etunimi'
    },
    lastName: {
      type: 'string',
      title: 'Sukunimi'
    },
    email: {
      type: 'string',
      title: 'Yhteyshenkilön sähköpostiosoite',
      pattern: '\\S+@\\S+\\.\\S+'
    }
  },
  dependencies: {
    firstName: ['lastName', 'email'],
    lastName: ['firstName', 'email'],
    email: ['firstName', 'lastName']
  }
};

export const TeacherUISchema = {
  firstName: {
    classNames: 'col-sm-6 del-padding'
  },
  lastName: {
    classNames: 'col-sm-6 del-padding'
  },
  email: {
    classNames: 'col-sm-6 del-padding',
    'ui:options': {
      inputType: 'email'
    }
  }
};
