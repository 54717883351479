import { ROOT_URL } from '../constants'
import {fetchBuilder} from "./fetchBuilder";

export const FETCH_RESERVATION = "fetch_reservation";

export function fetchReservation(id) {

  const request = fetchBuilder(`${ROOT_URL}/reservations/cancel/${id}`, "GET")
    .then(res => res.json()).catch(err => console.log(err));

  return {
    type: FETCH_RESERVATION,
    payload: request
  }
}
