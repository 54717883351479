/* eslint camelcase: "off" */
/* global __webpack_public_path__ */
import { addLocaleData } from 'react-intl';
import getFilenameFromLocale from './getFilenameFromLocale';
import tryImport from './tryImport';
import { translations } from '.';

// Download internationalization data and translations.
export default async locale => {
  await tryImport(
    async attempt => import(`intl/locale-data/jsonp/${attempt}.js`),
    locale,
    'locale',
    'language'
  );
  addLocaleData(
    await tryImport(
      async attempt => import(`react-intl/locale-data/${attempt}.js`),
      locale,
      'language'
    )
  );

  const response = await fetch(
    `${__webpack_public_path__}${getFilenameFromLocale(locale)}`,
    {
      credentials: 'same-origin'
    }
  );
  if (response.ok) {
    translations.set(locale, await response.json());
  }
};
